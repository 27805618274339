<template>
  <div class="reset-password">
    <div class="reset-password__form">
      <div class="logo">
        <img src="@/assets/img/eden_logo.svg" alt="Eden" />
      </div>

      <el-form
        :model="passwordForm"
        label-position="top"
        ref="passwordForm"
        class="fadeIn"
      >
        <el-row type="flex" class="is-flex-wrap mt-2">
          <el-col :md="24">
            <el-alert type="success" class="small" :closable="false">
              <template slot="title">
                <div class="el-alert--content">
                  <p v-if="sendingCode">
                    Resending password reset code to
                    <span class="text-bold text-grey-tertiary">{{
                      email
                    }}</span>
                  </p>
                  <p v-else>
                    Check
                    <span class="text-bold text-grey-tertiary">{{
                      email
                    }}</span>
                    for your code. Didn't get code?
                    <span
                      class="text-primary text-bold link"
                      @click="resendCode"
                      >Resend</span
                    >
                  </p>
                </div>
              </template>
            </el-alert>
          </el-col>
        </el-row>
        <el-row type="flex" class="is-flex-wrap">
          <el-col :md="24">
            <el-form-item
              label="Enter code"
              prop="code"
              :rules="validateField()"
            >
              <el-input type="text" autofocus v-model="passwordForm.code" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="is-flex-wrap">
          <el-col :md="24">
            <el-form-item
              label="Enter new password"
              prop="password"
              :rules="validateField()"
            >
              <el-input
                type="password"
                :class="'password'"
                v-model="passwordForm.password"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" class="is-flex-wrap">
          <el-col :md="24">
            <el-form-item
              label="Re-enter new password"
              prop="confirm_new_pwd"
              :rules="rules.confirm_new_pwd"
            >
              <el-input
                type="password"
                :class="'confirm_pwd'"
                v-model="passwordForm.confirm_new_pwd"
                @keyup.enter.native="resetPassword"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <div class="mt-10 flex justify-center">
          <el-button :loading="changing" type="primary" @click="resetPassword"
            >Reset password</el-button
          >
        </div>
        <div class="mt-10 flex justify-center">
          <el-button type="text" @click="$router.go(-1)">Back</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import auth from "@/requests/auth";

export default {
  name: "ResetPassword",
  data() {
    const confirmPassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Re-enter your password"));
      } else if (value !== this.passwordForm.password) {
        callback(new Error("Passwords do not match"));
      } else {
        callback();
      }
    };
    return {
      email: "",
      sendingCode: false,
      passwordForm: {
        code: null,
        password: "",
        confirm_new_pwd: "",
      },
      changing: false,
      rules: {
        confirm_new_pwd: [{ validator: confirmPassword, trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.email = localStorage.getItem("eden-password-reset-email");
  },
  methods: {
    resendCode() {
      this.sendPasswordCode();
    },

    sendPasswordCode() {
      this.sendingCode = true;
      const payload = { email: this.email };
      auth
        .sendResetCode(payload)
        .then((response) => {
          this.sendingCode = false;
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$refs.passwordForm.resetFields();
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          this.sendingCode = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    resetPassword() {
      this.$refs.passwordForm.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.changing = true;
        auth
          .resetPassword(this.passwordForm)
          .then((response) => {
            this.changing = false;
            if (response.data.status) {
              this.$message.success(response.data.message);
              localStorage.removeItem("eden-password-reset-email");
              this.$router.push({ name: "login" });
            } else {
              this.$message.error(response.data.message);
            }
          })
          .catch((error) => {
            this.changing = false;
            const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          });
        return true;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.reset-password {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .logo {
    height: 100px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__form {
    width: 80%;
    max-width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .el-form {
      width: 100%;
    }

    .password-reset {
      margin-top: 20px;
    }
    .link {
      cursor: pointer;
    }
  }
}
</style>
